import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import EmailIcon from "@mui/icons-material/Email";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TrafficIcon from "@mui/icons-material/Traffic";


import { useAuth } from "../../../hooks/AuthProvider";
import { getHeaders } from '../../../utils/apis';

import { tokens } from "../theme";
import { mockTransactions } from "../data/mockData";
import Header from "../components/Header";
import LineChart from "../components/LineChart";
import GeographyChart from "../components/GeographyChart";
import BarChart from "../components/BarChart";
import StatBox from "../components/StatBox";
import ProgressCircle from "../components/ProgressCircle";
import PieChart from '../components/PieChart';
import PieChartWithCustomizedLabel from '../components/MuiPieChart';


const ColorsTable = [
    tokens("dark").greenAccent[500],
    tokens("dark").blueAccent[300],
    tokens("dark").redAccent[200],
    tokens("dark").primary[400],
    tokens("dark").grey[300],
    tokens("dark").greenAccent[200],
    tokens("dark").blueAccent[500],
    tokens("dark").redAccent[400],
    tokens("dark").primary[100],
    tokens("dark").grey[500],
];

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function DashBoard(props) {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [recentOrders, setRecentOrders] = useState([]);
    const [fabricStatData, setFabricStatData] = useState([]);

    const [rptData, setRptData] = useState([]);

    const [dashboardType, setDashboardType] = useState('');

    const [pieData, setPieData] = useState([]);
    const [lineData, setLineData] = useState([]);

    const [showData, setShowData] = useState({
        totalClients: 0,
        totalNewClientsPercent: 0.0,
        totalNewClients: 0,
        totalOrders: 0,
        totalShirts: 0,
        totalWaitOrders: 0,
    });

    const auth = useAuth();

    const PrepareData = (rowData) => {
        setRptData(rowData);

        //  console.log(rowData);
        let rptSet = rowData.reduce(function (map, obj) {
            //console.log(obj);
            map[obj.id] = obj.data;
            return map;
        }, {});
        // console.log(rptSet);

        // console.debug(rptSet);
        let showDat = {
            totalNewClients: rptSet[5],
            totalNewClientsPercent: Math.round((parseFloat(rptSet[5]) / parseFloat(rptSet[6]) * 100) * 100) / 100,
            totalClients: rptSet[6],
            totalOrders: rptSet[1],
            totalShirts: rptSet[3],

            totalWaitOrders: parseInt(rptSet[8]) + parseInt(rptSet[9]) + parseInt(rptSet[10]) + parseInt(rptSet[11]) + parseInt(rptSet[12]),
        }


        setPieData([
            { label: 'New Orders', value: parseInt(rptSet[8]), color: '#0088FE' },
            { label: 'Accepted Orders', value: parseInt(rptSet[9]), color: '#00C49F' },
            { label: 'Approved Orders', value: parseInt(rptSet[10]), color: '#FFBB28' },

            { label: 'Processing Orders', value: parseInt(rptSet[12]), color: '#FF8042' },
            { label: 'Cancel Orders', value: parseInt(rptSet[11]), color: 'gray' },
        ]);

        setShowData(showDat);

    }

    const ProcessTailorData = (rowData) => {
        // console.debug(rowData);

        const usr_list = [...new Set(rowData.map(item => item.usr_name))]; // [ 'A', 'B']
        let date_list = [...new Set(rowData.map(item => item.stat_date))];
        date_list.reverse();
        // console.debug(usr_list);
        // console.debug(date_list);
        // console.debug(label_list);


        let data = usr_list.map((usr, idx) => {
            return {
                id: usr, data:
                    date_list.map(date => { return { x: (date + "").substring(5, 10), y: 0, date } })
                , color: ColorsTable[idx % ColorsTable.length]
            }
        });

        data.forEach((usr) => {
            let sum = 0;
            usr.data.forEach((data) => {
                for (let i = 0; i < rowData.length; i++) {
                    if ((rowData[i].usr_name === usr.id) &&
                        (rowData[i].stat_date === data.date)) {
                        data.y = parseInt(rowData[i].num_of_shirts);

                        sum = sum + data.y;

                        break;
                    }

                }
            })
            usr['sum'] = sum;
        })

        data.sort((a, b) => a.sum - b.sum)

        //    console.debug('data=', data);
        setLineData(data);

    }

    const loadTailorData = () => {

        fetch("/api/rptTailorSummary", {
            method: "GET",
            headers: getHeaders(auth),
            //body: JSON.stringify(data),
        })
            .then((response) => response.json())
            //.then((jsonData) => addId(jsonData, "client_id"))
            .then((rowData) => {
                if (rowData["result"]) { auth.logOut(); return; }

                PrepareData(rowData);

            })
            .catch((error) => console.log(error));
        //fetchData();

        fetch("/api/rptTailorRecentOrder", {
            method: "GET",
            headers: getHeaders(auth),
            //body: JSON.stringify(data),
        })
            .then((response) => response.json())
            //.then((jsonData) => addId(jsonData, "client_id"))
            .then((rowData) => {
                if (rowData["result"]) { auth.logOut(); return; }
                rowData.forEach(e => {
                    e['data'] = JSON.parse(e['data_json']);
                    e['date'] = e.order_time.substring(0, 10);
                });

                setRecentOrders(rowData);

            })
            .catch((error) => console.log(error));


        // Report Tailor
        fetch("/api/rptTailorClientSumLimit/" + auth.user['tailorshop_id'], {
            method: "GET",
            headers: getHeaders(auth),
            //body: JSON.stringify(data),
        })
            .then((response) => response.json())
            //.then((jsonData) => addId(jsonData, "client_id"))
            .then((rowData) => {
                if (rowData["result"]) { auth.logOut(); return; }
                // const rowData2 = addId(rowData, "client_id");
                // setGridData(rowData2);
                ProcessTailorData(rowData);
            })
            .catch((error) => console.log(error));

    }

    const loadTailorShopData = () => {

        fetch("/api/rptSummary", {
            method: "GET",
            headers: getHeaders(auth),
            //body: JSON.stringify(data),
        })
            .then((response) => response.json())
            //.then((jsonData) => addId(jsonData, "client_id"))
            .then((rowData) => {
                if (rowData["result"]) { auth.logOut(); return; }

                PrepareData(rowData);

            })
            .catch((error) => console.log(error));
        //fetchData();


        fetch("/api/rptRecentOrder", {
            method: "GET",
            headers: getHeaders(auth),
            //body: JSON.stringify(data),
        })
            .then((response) => response.json())
            //.then((jsonData) => addId(jsonData, "client_id"))
            .then((rowData) => {
                if (rowData["result"]) { auth.logOut(); return; }
                rowData.forEach(e => {
                    e['data'] = JSON.parse(e['data_json']);
                    e['date'] = e.order_time.substring(0, 10);
                });

                setRecentOrders(rowData);

            })
            .catch((error) => console.log(error));


        // Report Tailor
        fetch("/api/rptClientSumLimit/" + auth.user['tailorshop_id'], {
            method: "GET",
            headers: getHeaders(auth),
            //body: JSON.stringify(data),
        })
            .then((response) => response.json())
            //.then((jsonData) => addId(jsonData, "client_id"))
            .then((rowData) => {
                if (rowData["result"]) { auth.logOut(); return; }
                // const rowData2 = addId(rowData, "client_id");
                // setGridData(rowData2);
                ProcessTailorData(rowData);
            })
            .catch((error) => console.log(error));


        fetch("/api/rptFabricByDate/" + auth.user['tailorshop_id'], {
            method: "GET",
            headers: getHeaders(auth),
            //body: JSON.stringify(data),
        })
            .then((response) => response.json())
            //.then((jsonData) => addId(jsonData, "client_id"))
            .then((rowData) => {
                if (rowData["result"]) { auth.logOut(); return; }
                // const rowData2 = addId(rowData, "client_id");
                setFabricStatData(rowData);
            })
            .catch((error) => console.log(error));
        //fetchData();
    }

    useEffect(() => {

        if (!auth.user) {
            auth.logOut();
            return;
        }


        fetch("/api/dashboardType", {
            method: "GET",
            headers: getHeaders(auth),
            //body: JSON.stringify(data),
        })
            .then((response) => response.json())
            //.then((jsonData) => addId(jsonData, "client_id"))
            .then((rowData) => {
                if (rowData["result"]) { auth.logOut(); return; }
                setDashboardType(rowData['dashboardType']);
                let dbType = rowData['dashboardType'];
                console.log(dbType);
                if (dbType === 'TAILOR') {
                    loadTailorData();
                } else if (dbType === 'TAILORSHOP') {
                    loadTailorShopData();
                }

            })
            .catch((error) => console.log(error));
        //fetchData();


    }, []);

    return (
        <Box style={{ width: "1024px", margin: "auto" }}>
            {/* HEADER */}
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="DASHBOARD" />

                {/* <Box>
                    <Button
                        sx={{
                            backgroundColor: colors.blueAccent[700],
                            color: colors.grey[100],
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",
                        }}
                    >
                        <DownloadOutlinedIcon sx={{ mr: "10px" }} />
                        Download Reports
                    </Button>
                </Box> */}
            </Box>

            {/* GRID & CHARTS */}
            <Box
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gridAutoRows="140px"
                gap="20px"
            >
                {/* ROW 1 */}
                <Box
                    gridColumn="span 3"
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <StatBox
                        title={showData.totalClients}
                        subtitle="Total Clients"
                        progress="1"
                        increase="-%"
                        icon={
                            <EmailIcon
                                sx={{ color: colors.blueAccent[600], fontSize: "26px" }}
                            />
                        }
                    />
                </Box>
                <Box
                    gridColumn="span 3"
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <StatBox
                        title={showData.totalNewClients}
                        subtitle="New Clients"
                        progress={showData.totalNewClientsPercent / 100}
                        increase={showData.totalNewClientsPercent + " %"}
                        icon={
                            <PersonAddIcon
                                sx={{ color: colors.blueAccent[600], fontSize: "26px" }}
                            />
                        }
                    />
                </Box>
                <Box
                    gridColumn="span 3"
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <StatBox
                        title={showData.totalOrders}
                        subtitle="Orders"
                        progress="0.50"
                        increase="-"
                        icon={
                            <PointOfSaleIcon
                                sx={{ color: colors.blueAccent[600], fontSize: "26px" }}
                            />
                        }
                    />
                </Box>

                <Box
                    gridColumn="span 3"
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <StatBox
                        title={showData.totalShirts}
                        subtitle="Total Shirts"
                        progress="0.80"
                        increase="+0%"
                        icon={
                            <TrafficIcon
                                sx={{ color: colors.blueAccent[600], fontSize: "26px" }}
                            />
                        }
                    />
                </Box>

                {/* ROW 2 */}
                <Box
                    gridColumn="span 8"
                    gridRow="span 2"
                    backgroundColor={colors.primary[400]}
                >
                    <Box
                        mt="25px"
                        p="0 30px"
                        display="flex "
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Box>
                            <Typography
                                variant="h5"
                                fontWeight="600"
                                color={colors.grey[100]}
                            >
                                Shirts by Tailor{dashboardType !== 'TAILOR' && <>s</>}

                            </Typography>
                            <Typography
                                variant="h3"
                                fontWeight="bold"
                                color={colors.blueAccent[500]}
                            >
                                {showData.totalShirts}
                            </Typography>
                        </Box>
                        <Box>
                            <IconButton>
                                <DownloadOutlinedIcon
                                    sx={{ fontSize: "26px", color: colors.blueAccent[500] }}
                                />
                            </IconButton>
                        </Box>
                    </Box>
                    <Box height="250px" m="-20px 0 0 0">
                        <LineChart lineData={lineData} />
                    </Box>
                </Box>
                <Box
                    gridColumn="span 4"
                    gridRow="span 2"
                    backgroundColor={colors.primary[400]}
                    overflow="auto"
                >
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        borderBottom={`4px solid ${colors.primary[500]}`}
                        colors={colors.grey[100]}
                        p="15px"
                    >
                        <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
                            Recent Orders
                        </Typography>
                    </Box>
                    {recentOrders.map((order, i) => (
                        <Box
                            key={`${order.order_id}-${i}`}
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            borderBottom={`4px solid ${colors.primary[500]}`}
                            p="15px"
                        >
                            <Box>
                                <Typography
                                    color={colors.blueAccent[500]}
                                    variant="h5"
                                    fontWeight="600"
                                >
                                    {order.order_prefix} {order.order_id}
                                </Typography>
                                <Typography color={colors.grey[100]}>
                                    {order.data.full_name}
                                </Typography>
                            </Box>
                            <Box color={colors.grey[100]}>{order.date}</Box>
                            <Box
                                backgroundColor={colors.greenAccent[500]}
                                p="5px 10px"
                                borderRadius="4px"
                            >
                                {order.num_of_shirts === "1" ? <>1 shirt</> : <>{order.num_of_shirts} shirts</>}
                            </Box>
                        </Box>
                    ))}
                </Box>

                {/* ROW 3 */}
                <Box
                    gridColumn="span 4"
                    gridRow="span 2"
                    backgroundColor={colors.primary[400]}
                    p="30px"
                >
                    <Typography variant="h6" fontWeight="500">
                        {showData.totalWaitOrders} Orders In The Queue
                    </Typography>
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        mt="25px"
                    >
                        <PieChartWithCustomizedLabel data={pieData} />
                        <Typography
                            variant="h6"
                            color={colors.blueAccent[500]}
                            sx={{ mt: "15px" }}
                        >

                        </Typography>

                    </Box>
                </Box>
                <Box
                    gridColumn="span 4"
                    gridRow="span 2"
                    backgroundColor={colors.primary[400]}
                >
                    <Typography
                        variant="h6"
                        fontWeight="500"
                        sx={{ padding: "30px 30px 0 30px" }}
                    >
                        Fabrics Quantity
                    </Typography>
                    <Box height="250px" mt="-20px">
                        <BarChart data={fabricStatData} isDashboard={true} />
                    </Box>
                </Box>
                <Box
                    gridColumn="span 4"
                    gridRow="span 2"
                    backgroundColor={colors.primary[400]}
                    padding="30px"
                >
                    <Typography
                        variant="h5"
                        fontWeight="600"
                        sx={{ marginBottom: "15px" }}
                    >
                        Geography Based Orders
                    </Typography>
                    <Box height="200px">
                        <GeographyChart isDashboard={true} />
                    </Box>
                </Box>
            </Box>
            {dashboardType}
        </Box>
    );

}

