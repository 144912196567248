import React, { useState, useEffect } from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import SearchIcon from '@mui/icons-material/Search';
import Rating from '@mui/material/Rating';
import { useAuth } from "../../../hooks/AuthProvider";
import { getHeaders } from '../../../utils/apis';
import Collapse from '@mui/material/Collapse';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import { useSpring, animated } from '@react-spring/web';


import './ButtonSelector.css';
import { styled } from '@mui/material';


export default function ButtonSelector(props) {

    const [searchText, setSearchText] = useState("");
    const [activeButton, setActiveButton] = useState("");
    const [viewButtons, setViewButton] = useState([]);

    const auth = useAuth();
    if (auth.user === null || auth.user === undefined) {
        auth.logOut();
        // return;
    }

    const curData = props.orderData;

    const changeSearchText = (event) => {
        setSearchText(event.target.value);
    }

    const handleSearchBtn = (event) => {
        if (searchText == null || searchText == undefined || searchText === "") {
            setSearchText(null);
        }
    }

    const handleImgClick = (event) => {

        setActiveButton(event.target.id);

        let button = viewButtons.find(obj => obj['button_code'] === event.target.id);
     //   console.debug("DEBUG: Buttons=", button)

        props.selectButton(button, true);
        //   console.log(event.target);
    }



    useEffect(() => {

        fetch("/api/buttons/" + auth.user['tailorshop_id'], {
            method: "GET",
            headers: getHeaders(auth),
            //body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((rowData) => {
                if (rowData["result"]) { auth.logOut(); return; }
                // const rowData2 = addId(rowData, "client_id");
                // setFabricsData(rowData);
                console.debug(rowData);
                setViewButton(rowData);
            })
            .catch((error) => console.log(error));
        //fetchData();
    }, []);

    return (
        <>
            <div className='container-sm'>
                <ImageList sx={{ width: "100%", height: "400px" }} cols={8}>
                    {viewButtons && viewButtons.map((item) => (
                        <ImageListItem key={item.button_code}>
                            <div className='img-container'  >
                                <img
                                    srcSet={`${item.small_img}?auto=format&dpr=2 2x`}
                                    src={`${item.small_img}?auto=format`}
                                    alt={item.full_name}
                                    id={item.button_code}
                                    onClick={handleImgClick}
                                    style={{ display: "blocks" }}
                                    //, borderRadius: "10%", boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.4)"
                                    className='holder'
                                />
                                <br />
                                {item.button_code}
                                <div id={item.button_code} className="overlay" onClick={handleImgClick}>Click to select</div>
                            </div>
                            {(activeButton == item.button_code) ? <ImageListItemBar
                                title={item.full_name}
                                // subtitle={<span>code: {item.code}</span>}
                                position="below"
                                sx={{
                                    "& .MuiImageListItemBar-title": { color: "white", border: "1px solid blue", backgroundColor: "#1976d2" }, //styles for title
                                }} />
                                : <ImageListItemBar
                                    title={item.full_name}
                                    // subtitle={<span>code: {item.code}</span>}
                                    position="below"
                                    sx={{
                                        "& .MuiImageListItemBar-title": { color: "black" }, //styles for title
                                    }} />
                            }

                        </ImageListItem>
                    ))}
                </ImageList>
            </div>


        </>
    );
}